import { Notification } from '../../_shared/modules/agd-components/notifications/models/notification.model';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { ApiService } from '../http/api.service';
import { UserService } from '../services/user/user.service';
import { CuitChangedService } from '../services/common/cuit-changed/cuit-changed.service';
import { RazonesChangedService } from '../services/common/razones-changed/razones-changed.service';
import { LogoutService } from './logout.service';
import {
  Subscription, ReplaySubject, Observable, Subject
} from 'rxjs';
import { CuitService } from '../services/cuit/cuit.service';
import { FirebaseMessagingService } from '../services/firebase-messaging/firebase-messaging.service';
import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from '../../_shared/modules/agd-components/notifications/services/notifications/notifications.service';
import { ModulesPermissions, USER_ROLES } from 'src/app/_shared/helpers/applicationConstants';
import {
  NotificationSnackbarComponent
} from 'src/app/_shared/modules/agd-components/notifications/components/notification-snackbar/notification-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalsService } from '../../_modules/pages/modules/venta-de-granos/components/common/modals/services/modals.service';
import { GoogeTagService } from '../services/google-tag-manager/googe-tag-manager.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService implements OnDestroy {
  /**
   * Notification variables
   */

  notificationsCount = 0;

  notificationsSubject: ReplaySubject<any> = new ReplaySubject(1);

  hasActiveCuits: Observable<boolean>;

  /**
   * Array to store all active cuit's permissions.
   */
  activeCuitPermissions: any;

  /**
   * Array to store all user's permissions.
   */
  activeUserPermissions: any;

  /**
   * Array to store active cuit rol.
   */
  cuitRol: string;

  businessName: string;

  /**
   * Array of CUIT's data: cuit, razon social & state (activo/inactivo).
   */
  userCuitsData: any;

  user: any;

  activeCUIT: string;

  /**
   * Indicates if site is on maintenance or not.
   */
  private siteOnMaintenance = false;

  private logoutSub: Subscription;

  // indica si el usuario solicitó cerrar sesión
  private logoutRequested = false;

  /**
   * Variables that helps to inform if user has or has not CUITs.
   */
  private hasActiveCuitsSubject = new ReplaySubject<boolean>(0);

  /**
   * Productor Role.
   */
  private PRODUCTOR_ROLE = 'P';

  /**
   * Local storage items
   */
  private LOCAL_STORAGE_ITEMS = {
    VERIFY_PHONE: 'verify-phone',
    MESSAGE: 'message',
    ACTIVE_CUIT: 'active-cuit',
  };

  private notificationRequestUnsubber: Subject<any> = new Subject();

  constructor(
    private http: ApiService,
    private authService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private cuitService: CuitService,
    private cuitChangedService: CuitChangedService,
    private razonesChangedService: RazonesChangedService,
    private logoutService: LogoutService,
    private firebaseService: FirebaseMessagingService,
    private notificationsService: NotificationsService,
    private snackBar: MatSnackBar,
    private modalsService: ModalsService,
    private readonly googleTagService: GoogeTagService,
  ) {
    this.hasActiveCuits = this.hasActiveCuitsSubject.asObservable();
    this.logoutSub = this.logoutService.userLogoutEvent.subscribe((sessionExpired) => {
      this.logoutUser(!sessionExpired);
    });
  }

  ngOnDestroy() {
    if (this.logoutSub) {
      this.logoutSub.unsubscribe();
    }
  }

  /**
   * Returns active user id.
   */
  getActiveUserId(): string {
    return this.user?.id;
  }

  /**
   * Gets all user's CUITs data from the backend and optionally sets a cuit as active if its a user's cuit
   * @param cuit optional cuit to set as active.
   */
  loadCuitsData(cuit?: string) {
    if (this.user) {
      this.setUserCuitsData(this.user.id, true, cuit);
    }
  }

  /**
   * Sets cuits details: cuit, razon social & state (activo/inactivo).
   * @param userId userId
   * @param emitChanges true if we have to notify changes.
   * @param cuit (optional) cuit to set as active.
   */
  setUserCuitsData(userId: any, emitChanges: boolean, cuit?: string) {
    this.userService.getUserCuits(
      userId
    ).subscribe(
      (res: any) => {
        this.userCuitsData = res.cuits;
        const newActiveCuit = cuit || this.getActiveCUIT() || this.userCuitsData.find(r => r.activo)?.cuit;
        if (newActiveCuit) {
          // If it's user's first CUIT, we redirect him to dashboard and charge data.
          // Else if we don't have an active CUIT or
          // We removed the active CUIT, set a new active CUIT. The first active one in the list.
          if (this.router.url.includes('cuits-inactivos')) {
            this.router.navigateByUrl('dashboard');
            this.setActiveCUIT(newActiveCuit);
          } else if (!this.getActiveCUIT()
            || !this.user.cuits.find(c => c.cuit === this.getActiveCUIT())) {
            this.setActiveCUIT(newActiveCuit);
          } else if (emitChanges) {
            this.setActiveCUIT(newActiveCuit || this.getActiveCUIT());
          }
          this.razonesChangedService.razonesChanged(this.userCuitsData);
        } else {
          this.businessName = '';
          this.hasActiveCuitsSubject.next(false);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  /**
   * Return array of user's cuits and its data.
   */
  getUserCuits() {
    return this.userCuitsData;
  }

  /**
   * Sets user data. Also Validates if we need to ask him his phone number.
   * If returnUrl is set, we redirect the user to that page after successful login.
   * @param user user
   * @param returnUrl returnUrl
   * @param cuit (optional) cuit to set as active after the user is logged in
   */
  logInUser(user: any, returnUrl?: string, cuit?: string) {
    // Sets token, refresh token and headers.
    this.authService.setToken(user.token);
    this.authService.setRefreshToken(user.refreshToken);
    this.http.setAuthorizationHeader();

    // Habilitar recepcion de notificaciones push para el usuario logueado.
    this.firebaseService.suscribeToMessages();

    // Sets user, its permissions and cuits.
    this.user = user.user;
    this.setUserCuitsData(this.user.id, true, cuit);
    this.activeUserPermissions = this.user.permissions;

    // we force users with agri pago role to set their phone number.
    if (this.hasPermission(USER_ROLES.AGRI_PAGO) && this.user.phone_verified === 0) {
      localStorage.setItem(this.LOCAL_STORAGE_ITEMS.VERIFY_PHONE, 'true');
      this.router.navigate(['/validar-telefono'], { queryParams: { returnUrl, cuit } });
    }
    // for users that don't have agri pago role, navigates to dashboard and if they haven't set their phone, we ask for it.
    if (this.user.phone) {
      this.router.navigate([returnUrl || '/dashboard'], { queryParams: { cuit } });
    } else {
      this.router.navigate([returnUrl || '/dashboard'], { state: { action: 'askForPhone' } });
    }
  }

  /**
   * Clears user object.
   */
  clearUser(): void {
    this.user = null;
    this.activeCUIT = null;
    this.hasActiveCuitsSubject.complete();
    this.hasActiveCuitsSubject = new ReplaySubject<boolean>(0);
    this.hasActiveCuits = this.hasActiveCuitsSubject.asObservable();
    localStorage.removeItem(this.LOCAL_STORAGE_ITEMS.ACTIVE_CUIT);
    localStorage.removeItem(this.LOCAL_STORAGE_ITEMS.VERIFY_PHONE);
    this.firebaseService.unsuscribeToMessages();
  }

  /**
   * Logout user by clearing user and object authService.
   * Also redirect user to login page.
   */
  async logoutUser(deleteToken = true): Promise<boolean> {
    this.logoutRequested = true;
    const loggedOut = this.router.navigateByUrl('login').then(navigated => {
      if (navigated) {
        const firebaseToken = this.firebaseService.getToken();

        this.clearUser();
        this.authService.clearAll();
        if (deleteToken) {
          this.userService.logout(firebaseToken).subscribe();
        }
      }
      // resetear variable a falso una vez deslogueado
      this.logoutRequested = false;
      return navigated;
    });

    return loggedOut;
  }

  /**
   * If user is logged in returns true.
   * Otherwise, return false.
   */
  userIsLoggedIn(): boolean {
    return !this.logoutRequested && this.authService.checkTokenExists();
  }

  /**
   * Returns user's name.
   */
  getUserName(): string {
    return (this.user) ? this.user.name : 'NaN';
  }

  /**
   * Returns the current selected CUIT for the user.
   */
  getActiveCUIT(): string {
    return localStorage.getItem(this.LOCAL_STORAGE_ITEMS.ACTIVE_CUIT) || this.activeCUIT;
  }

  /**
   * Returns 'verify-phone' item from local storage.
   */
  getUserHasToVerifyPhone(): string {
    return localStorage.getItem(this.LOCAL_STORAGE_ITEMS.VERIFY_PHONE);
  }

  /**
   * Removes 'verify-phone' item from local storage.
   */
  removeVerifyPhoneItem() {
    localStorage.removeItem(this.LOCAL_STORAGE_ITEMS.VERIFY_PHONE);
  }

  /**
   * Returns true if 'message' item exists.
   */
  showMessage(): boolean {
    if (localStorage.getItem(this.LOCAL_STORAGE_ITEMS.MESSAGE)) {
      return true;
    }
    return false;
  }

  /**
   * Removes 'message' item from local storage.
   */
  removeMessageFlag() {
    localStorage.removeItem(this.LOCAL_STORAGE_ITEMS.MESSAGE);
  }

  /**
   * Returns the current selected CUIT for the user.
   */
  getActiveCUITPermissions(): any[] {
    return (this.user) ? this.activeCuitPermissions : null;
  }

  /**
   * Returns active user's permissions.
   */
  getActiveUserPermissions(): string {
    return (this.user) ? this.activeUserPermissions : null;
  }

  /**
   * Returns the current selected CUIT rol.
   */
  getActiveCUITRol(): string {
    return (this.user) ? this.cuitRol : null;
  }

  /**
   * If active CUIT role is 'Productor', returns true.
   * Otherwise returns false.
   */
  isProductor(): boolean {
    return this.getActiveCUITRol() && this.getActiveCUITRol() === this.PRODUCTOR_ROLE;
  }

  /**
   * If CUITs role by parameter is 'Productor' returns true.
   */
  isRoleProductor(cuitRole) {
    return cuitRole === this.PRODUCTOR_ROLE;
  }

  /**
   * Returns user's CUITs array.
   */
  getUserCUITs(): any {
    return this.user ? this.user.cuits : null;
  }

  /**
   * Gets user data.
   * @returns user
   */
  async getUserData(): Promise<any> {
    let data: any;
    try {
      data = await this.userService.getUserData().toPromise();
      if (data) {
        this.user = data.usuario;
        this.activeUserPermissions = this.user.permissions;
      }
    } catch (e) {
      console.error(e);
    }
    return this.user;
  }

  getUser(): any {
    return this.user;
  }

  /**
   * Sets user's CUITs array.
   * @param cuits cuits.
   * @param emitChanges true if we have to notify changes.
   */
  setUserCUITs(cuits: any[], emitChanges: boolean) {
    if (this.user) {
      this.user.cuits = cuits;
      this.setUserCuitsData(this.user.id, emitChanges);
    }
  }

  /**
   * Sets the CUIT that'll be used for all future
   * requests.
   * @param cuit cuit
   */
  setActiveCUIT(cuit: string): any {
    localStorage.setItem(this.LOCAL_STORAGE_ITEMS.ACTIVE_CUIT, cuit);
    this.activeCUIT = cuit;
    this.hasActiveCuitsSubject.next(true);
    this.notificationRequestUnsubber.next(null);
    this.cuitService.getPermissions(cuit).subscribe(
      (res: any) => {
        this.activeCuitPermissions = res.modulosDisponibles;
        this.cuitRol = res.rol;
        this.businessName = res.razonSocial;
        this.notificationsService.setGridsNotifications(this.activeCuitPermissions);
        if (res.ventaGranosAceptarTyC) {
          this.modalsService.showTOSDialog();
        }

        const activeCuitData = this.userCuitsData.find(cd => cd.cuit === cuit);
        const activeCuitDataValue = activeCuitData && JSON.parse(JSON.stringify(activeCuitData));
        this.cuitChangedService.cuitPermissionsChanged(this.activeCuitPermissions, activeCuitDataValue);
        this.getNotificationsCount();

        // Enviar informacion del CUIT activo a Google Tag Manager.
        this.googleTagService.sendActiveCuitEvent(res);
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  /**
   * Gets unseen notifications count related to user and active CUIT.
   */
  getNotificationsCount() {
    // Cancel ongoing requests.
    this.notificationRequestUnsubber.next(null);
    this.notificationsService
      .getNotificationsCount(this.activeCUIT)
      .pipe(takeUntil(this.notificationRequestUnsubber))
      .subscribe(
        (res: any) => {
          this.notificationsCount = res.cantidadTotal;
          this.notificationsSubject.next(null);
        },
        (err: any) => {
          console.error(err);
        }
      );
  }

  /**
   * Manages Push notifications actions.
   * @param notificationData notification data.
   */
  onPushNotification(notificationData: Notification) {
    // Shows the notification depending on the module condition.
    let shouldShow = false;

    if (Array.isArray(notificationData.cuit)) {
      const activeCUIT = this.getActiveCUIT();

      shouldShow = notificationData.cuit.includes(activeCUIT);
    } else {
      // TODO: delete after modification in "precios" y "ST"
      // Checks if we should show notification
      switch (notificationData.modulo_id) {
        case ModulesPermissions.PRECIOS:
        case ModulesPermissions.CUENTA_CORRIENTE:
          if (this.isProductor()) {
            shouldShow = true;
          }
          break;
        case ModulesPermissions.COMPROBANTES:
          if (!this.isProductor()) {
            shouldShow = true;
          }
          break;
        case ModulesPermissions.MEDIA:
          shouldShow = true;
          break;
        default:
      }
    }

    if (shouldShow) {
      // Show push notification.
      this.snackBar.openFromComponent(NotificationSnackbarComponent, {
        duration: 10000,
        data: {
          notification: notificationData,
          userId: this.user.id
        },
        verticalPosition: 'top'
      });
      // Update module notifications.
      this.cuitService.getPermissions(this.activeCUIT).subscribe({
        next: (res: any) => {
          this.notificationsService.setGridsNotifications(res.modulosDisponibles);
        },
        error: (err: any) => {
          console.error(err);
        }
      });
      // Update in-app notifications count.
      this.getNotificationsCount();
    }
  }

  /**
   * Sets amount of notifications that were not read and emit changes.
   * @param count
   */
  setNotificationsCount(count: number) {
    this.notificationsCount = count;
    this.notificationsSubject.next(null);
  }

  /**
   * Returns true if user has the permission passed by parameter.
   * Otherwise returns false.
   * @param permission permission
   */
  hasPermission(permissionName: string): boolean {
    for (let i = 0; i < this.activeUserPermissions?.length; i++) {
      if (this.activeUserPermissions[i] === permissionName) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns true if user has the module passed by parameter.
   * Otherwise returns false.
   * @param moduleName module
   */
  hasModulePermission(moduleName: string): boolean {
    for (let i = 0; i < this.activeCuitPermissions.length; i++) {
      if (this.activeCuitPermissions[i].moduloId === moduleName) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns true if user has permission to the grid passed by parameter.
   * Otherwise returns false.
   * @param moduleName module
   * @param gridName grid
   */
  hasGridPermission(moduleName: string, gridName: string): boolean {
    if (this.activeCuitPermissions) {
      for (let i = 0; i < this.activeCuitPermissions.length; i++) {
        if (this.activeCuitPermissions[i].moduloId === moduleName) {
          if (this.activeCuitPermissions[i].grillasId.find((grid) => grid === gridName)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Sets siteOnMaintenance value.
   * @param siteOnMaintenance siteOnMaintenance
   */
  setSiteOnMaintenance(siteOnMaintenance: boolean) {
    this.siteOnMaintenance = siteOnMaintenance;
  }

  /**
   * Returns true if site is under maintenance. If not, returns false.
   */
  isSiteOnMaintenance(): boolean {
    return this.siteOnMaintenance;
  }

  /**
   * Returns true if the active CUIT has permissions.
   * Otherwise returns false.
  */
  hasCUITPermissions(cuitData, permissionValue) {
    return !!cuitData?.[permissionValue];
  }

  hasAgripagoRole() {
    return this.hasUserCUITRole(USER_ROLES.AGRI_PAGO);
  }

  hasReddyRole() {
    return this.hasUserCUITRole(USER_ROLES.REDDY);
  }

  hasVentaGranosRole() {
    return this.hasUserCUITRole(USER_ROLES.VENTA_GRANOS);
  }

  /**
 * Return true if the active CUIT has permission to module.
 * @param moduloId modulo from ModulesPermissions enum
 * @returns boolean
 */
  checkModulePermission(moduloId: string) {
    return this.activeCuitPermissions?.some(
      permission => permission.moduloId === moduloId
    );
  }

  private hasUserCUITRole(role: USER_ROLES) {
    const hasUserRole = this.activeUserPermissions?.find(userRole => userRole === role);

    if (hasUserRole && this.userCuitsData) {
      const cuitData = this.userCuitsData.find(data => data.cuit === this.activeCUIT);

      switch (role) {
        case USER_ROLES.AGRI_PAGO:
          return !!cuitData.asociadoAgripago;
        case USER_ROLES.REDDY:
          return !!cuitData.asociadoReddy;
        case USER_ROLES.EXPERTA:
          return cuitData.asociadoExperta;
        case USER_ROLES.VENTA_GRANOS:
          return cuitData.asociadoVentaGranos;
        default:
          break;
      }
    }

    return false;
  }
}
