import { Component, Inject, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoDialogData } from '../../services/modals.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'agd-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  sanitizedBodyHTML: SafeHtml;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogData,
    private sanitizer: DomSanitizer,
  ) {
    this.sanitizedBodyHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.data.body);
  }
}

