import { DecimalPipe } from '@angular/common';
import {
  Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges
} from '@angular/core';

/**
 * Custom currency pipe to dislay numbers with 2 decimals
 */
@Directive({
  selector: '[agdCurrencyFormat]'
  })
export class CurrencyFormatDirective implements OnChanges {
  @Input('agdCurrencyFormat') value: string | number;

  constructor(
    private elem: ElementRef,
    private renderer: Renderer2,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.elem) {
      const el = this.elem.nativeElement as HTMLElement;

      const parsedInnerValue = parseFloat(changes.value.currentValue);

      // Only apply changes if value is a number
      if (!Number.isNaN(parsedInnerValue)) {
        // Get integer and decimal parts
        const int = this.decimalPipe.transform(Math.trunc(parsedInnerValue), '');
        const decimals = this.decimalPipe.transform(Math.trunc((parsedInnerValue % 1) * 100), '2.0-0');

        // Add integer part
        this.renderer.setProperty(el, 'innerHTML', int);

        // Add decimal part with custom style
        const decimalsElem = this.renderer.createElement('span');
        this.renderer.setStyle(decimalsElem, 'vertical-align', 'top');
        this.renderer.setStyle(decimalsElem, 'font-size', '.66em');

        const decimalsStr = this.renderer.createText(decimals);
        this.renderer.appendChild(decimalsElem, decimalsStr);
        this.renderer.appendChild(el, decimalsElem);
      }
    }
  }
}
