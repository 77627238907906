export const METODOS_ACREDITACION = Object.freeze({
  agripago: {
    icon: 'agripago',
    name: 'AgriPago'
  },
  reddy: {
    icon: 'reddy_icon_gris',
    icon_active: 'reddy_icon_gris',
    name: 'Reddy'
  },
  transferencia: {
    icon: 'bank_rounded',
    name: 'Transferencia'
  },
  cheque: {
    icon: 'bank_check',
    name: 'Cheque'
  },
  'a definir': {
    icon: '',
    name: 'A definir con el acopio'
  }
});
