import {
  Component, Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Precio } from '../../../../../models/venta-de-granos.models';

export interface UpdateData {
  title?: string;
  oldValue: {
    label: string;
    value: number | string;
    unit?: string;
  };
  newValue: {
    label: string;
    value: number | string;
    unit?: string;
  };
  warningMsg?: string;
  showSimulation?: {
    kgs: number;
    price: Precio;
  };
  hint?: string;
  disableConfirmationButtons?: boolean;
  closeButton?: boolean;
  customConfirmation?: {
    primary: { label: string; value: string };
    secondary: { label: string; value: string };
  };
}

@Component({
  selector: 'agd-value-change-dialog',
  templateUrl: './value-change-dialog.component.html',
  styleUrls: ['./value-change-dialog.component.scss']
})
export class ValueChangeDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateData,
  ) {
  }
}
