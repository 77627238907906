import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { METODOS_ACREDITACION } from '../../../../../models/metodos-acreditacion.models';
import { AccreditationValue } from '../../../../forms/seleccion-acreditacion/seleccion-acreditacion.component';

@Component({
  selector: 'agd-descartar-metodos-dialog',
  templateUrl: './descartar-metodos-dialog.component.html',
  styleUrl: './descartar-metodos-dialog.component.scss'
})
export class DescartarMetodosDialogComponent {
  instrumentos: { id: string; name: string; icon: string }[];

  constructor(@Inject(MAT_DIALOG_DATA) data: {
    dropValues: AccreditationValue;
  }) {
    this.instrumentos = [];

    data.dropValues.forEach(id => {
      const config = METODOS_ACREDITACION[id];

      this.instrumentos.push({
        id,
        ...config,
      });
    });
  }
}
