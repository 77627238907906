@if (!data.confirmation) {
    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="close">
        <mat-icon>close</mat-icon>
    </button>
}
@if (!data.hideAlert) {
    <mat-icon>priority_high</mat-icon>
}
<h3>{{ data.title }}</h3>
<p [innerHTML]="sanitizedBodyHTML"></p>
<ng-container *ngComponentOutlet="data.component"></ng-container>
@if (data.confirmation) {
    <div class="confirmation-btn">
        <button class="cancel" mat-dialog-close>{{ data.cancelButton }}</button
        ><button class="confirm" [mat-dialog-close]="true">{{ data.confirmButton }}</button>
    </div>
}
