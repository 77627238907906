/* eslint-disable no-shadow */
/**
 * File types.
 */
export enum FileTypeEnum {
  ORDEN_DE_PAGO = 'ordenDePago',
  RETENCIONES = 'retenciones',
}

/**
 * Modules Permissions.
 */
export enum ModulesPermissions {
  RESUMEN_GLOBAL = 'resumenGlobal',
  GRANOS = 'granos',
  CONTRATOS = 'contratos',
  CUENTA_CORRIENTE = 'ctaCte',
  INSUMOS = 'insumos',
  EXPERTA = 'experta',
  PRECIOS = 'preciosAgd',
  MEDIA = 'media',
  COMPROBANTES = 'comprobantes',
  VENTAS = 'ventas',
  PAGOS = 'pagos',
  MESA_DE_AYUDA = 'mesaDeAyuda',
  VENTA_GRANOS = 'ventaGranos'
}

/**
 * Modules Permissions.
 */
export enum GridsPermissions {
  COBROS = 'cobros',
  SALDO_GRANARIO = 'saldoGranario',
  DESCARGAS = 'descargas',
  CUENTA_CORRIENTE_GRANARIA = 'ctaCteGranaria',
  CALIDAD = 'calidad',
  TRANSFERENCIAS = 'transferencias',
  ULTIMOS_MOVIMIENTOS = 'ultimosMovimientos',
  APLICACIONES = 'aplicaciones',
  CONTRATOS_A_FIJAR = 'contratosAFijar',
  RETENCIONES = 'retenciones',
  POSICION_FISICA = 'posicionFisica',
  FACTURACION = 'facturacion',
  HISTORIAL_DE_COMPRAS = 'historialCompras',
  INFORMES = 'informes',
  POSICION_GRANARIA = 'posicionGranaria',
  CUENTA_CORRIENTE = 'cuentaCorriente',
  CONTRATOS = 'contratos',
  PRECIOS = 'precios',
  KG_A_FIJAR = 'kgAFijar',
  COMPROBANTES = 'comprobantes',
  RETENCIONES_AFIP = 'retencionesAfip',
  VENTAS = 'ventas',
  ULTIMOS_PAGOS = 'ultimosPagos',
  ENTREGADOS_SIN_FACTURAR = 'entregadosSinFacturar',
  RECLAMOS = 'reclamos',
  NUEVO_RECLAMO = 'nuevoReclamo',
  ANALISIS_DE_CALIDAD = 'analisisCalidad',
  ROMANEOS_CONTRATOS_A_FIJAR = 'romaneosContratosAFijar',
  DETALLE_CONSULTA = 'detalleConsulta'
}

/**
 * Regexs.
 */
export const REGEX = {
  CUIT: /^[0-9]{2}-[0-9]{8}-[0-9]{1}$/,
  PHONE: /^([0-9]{10}|-{3})$/,
  USERNAME: /^([0-9]|[a-z]|[A-Z])+$/,
  SMS_CODE: /^[0-9]{6}$/,
  NAME: /^([a-z]|[áéíóúñ]|\s)+$/i,
};

/**
 * User roles.
 */
export enum USER_ROLES {
  ADMIN = 'admin',
  COLABORADOR_AGD = 'agd_collaborator',
  AGRI_PAGO = 'agri_pago',
  MARKETING = 'marketing',
  MESA_DE_AYUDA = 'mesa_de_ayuda',
  EXPERTA = 'experta',
  VENTA_GRANOS = 'venta_de_granos',
  REDDY = 'reddy',
}

/**
 * 'Producto' names.
 */
export const PRODUCTO_NAMES = {
  GIRASOL: 'GIRASOL',
  TRIGO: 'TRIGO',
  SOJA: 'SOJA',
  SOJILLA: 'SOJILLA',
  SORGO: 'SORGO',
  MANI: 'MANI',
  MAIZ: 'MAIZ',
};

/**
 * Icon names.
 */
export const ICON_NAMES = {
  DEFAULT: 'icofont-plant',
  TRIGO: 'icofont-wheat',
  SOJA: 'flaticon-soybean',
  SORGO: 'icofont-crop-plant',
  MAIZ: 'icofont-corn',
};

/**
 * Image names.
 */
export const IMAGE_NAMES = {
  GIRASOL: 'icon_girasol.png',
  MANI: 'icon_mani.png',
};

/**
 * Precios Popover
 */
export const PRECIOS_POPOVER = {
  HEIGHT: 303,
  WIDTH: 675,
  ARROW_WIDTH: 17,
};

/**
 * Precios. Tipos de Negocio.
 */
export const TIPO_NEGOCIO = {
  ENTREGADO: 'disponible entregado',
  'A ENTREGAR': 'disponible a entregar',
  'N POSICIONES': 'forward',
  'PAGO DIFERIDO': 'pago diferido',
};

/**
 * Precios' Entregas.
 */
export enum ENTREGAS {
  ENTREGADO = 'ENTREGADO',
  A_ENTREGAR = 'A ENTREGAR',
  PAGO_DIFERIDO = 'PAGO DIFERIDO',
  N_POSICIONES = 'N POSICIONES',
}
/**
 * Mobile app stores URLs.
 */
export const MOBILE_APP_STORE_URLS = {
  ANDROID: 'https://play.google.com/store/apps/details?id=agd.unco.clientes',
  IOS: 'https://apps.apple.com/ar/app/agd-agro/id1517781986',
};
