<div class="dialog-content">
    <span class="description">
        Si decides asignar un valor <b>0</b> en algún método, este será automáticamente excluido para simplificar tu
        selección.
    </span>
    @for (item of instrumentos; track $index) {
        <div class="accreditation-method">
            <div>
                <mat-icon [svgIcon]="item.icon"></mat-icon>
                <span class="name">{{ item.name }}</span>
            </div>
            <span class="value"> 0 % </span>
        </div>
    }
    <span class="title"> ¿Estas seguro de querer continuar? </span>
</div>
